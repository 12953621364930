import { i18nifySimpleStrings } from '../../utils/useI18n';

export default {
  plural : {
    'There was an error processing your %%s' : [
      'There were errors processing your %%s',
      0,
      { translate : 'files' }
    ]
  },
  singular : {
    ...i18nifySimpleStrings([
      'Show',
      'Releases',
      'View my press releases',
      'All',
      'All Accounts',
      'No press Releases',
      'Stand by, Press Releases Loading...',
      'Views',
      'Clicks',
      'What would you like to do today?',
      'DASHBOARD',
      'Welcome',
      'Submit a Press Release',
      'Upload text, tables, and multimedia for distribution over the wire.',
      'Schedule a Regulatory Filing',
      'Submit documents to meet regulatory body filing requirements for a publicly-traded company.',
      'Get Started',
      'Today is',
      'View report',
      'Ready for Approval',
      'Ready for Approval with Revision',
      'Rejected',
      'Approved',
      'Submitted',
      'Scheduled',
      'Hold for Call',
      'Issued',
      'No Data, Please try again later',
      'From',
      'End',
      'EDGAR',
      'European Disclosure',
      'FCA',
      'Press Release (Fax / Email)',
      'Press Release (Wire)',
      'SEDAR',
      'Account Name',
      'Status',
      'Start Date',
      'End Date',
      'Release Type',
      'End date must be same or after start date',
      'Close',
      'Save',
      'Search',
      'Latest order compared to previous orders',
      'Last Order',
      'Last 30 Days',
      'Last release within the last 30 days',
      'views',
      'clicks',
      'shares',
      'ASAP',
      'Order ID',
      'View More',
      'Failed to load data. Please try again later.',
      'Hold For Call',
      'Order Description',
      'Filing Type',
      'Version',
      'Account',
      'Date',
      'Messages',
      'Status',
      'Work In Progress',
      'Ready For Release',
      'Release Issued',
      'Ready For Approval',
      'Ready For Approval with Revision',
      'Click to sort ascending / descending',
      'Work In Review',
      'You do not have access to the URL',
      'You have been redirected to a page you have access to',
      'Please try again or contact our web support at websupport@businesswire.com so we can advise you further',
      'Thank you',
      'Coming Soon',
      'Send Press Release',
      'Send Regulatory Filing',
      'Your Last Release',
      'Last 30 Days',
      'Ready to send your next release or earnings report?'
    ])
  }
};
